import * as React from 'react';
import {useEffect, useState} from 'react';
import {CircularInput, CircularThumb, CircularTrack} from 'react-circular-input';
import './priceModule.less';
import {Translation} from '@autopay.io/translation';
import {getEstimatedPrice} from '../../../../services/http';
import {EstimatedPriceViewState, Zone} from '../../types';
import * as moment from 'moment';
import {Moment} from 'moment';
import {Spinner} from '@autopay.io/style';
import {configuration} from './config';
import {colorDefinitions} from '@autopay.io/style/lib/definitions/colorDefinitions';

interface PriceModuleProps {
    operator: string;
    facilityCode: string;
    zone: Zone;
    setPriceCalculationError: (errorId: string | null) => void;
}

export const PriceModule = ({operator, facilityCode, zone, setPriceCalculationError}: PriceModuleProps) => {

    const [estimatedPrice, setEstimatedPrice] = useState<EstimatedPriceViewState>({type: 'LOADING'});
    const [parkUntilTime, setParkUntilTime] = useState<Moment>(configuration.default_park_until_time);

    const [completeCircles, setCompleteCircles] = useState<number>(0);
    const [value, setValue] = useState<number>(configuration.default_value);
    const [step, setStep] = useState<number>(configuration.default_step);
    const [totalMinutes, setTotalMinutes] = useState<number>(configuration.default_total_minutes);

    useEffect(() => {
        getEstimatedPriceForParking(totalMinutes);
    }, [zone]);

    function resetValues() {
        setPriceCalculationError(null);
        setCompleteCircles(0);
        setParkUntilTime(configuration.default_park_until_time);
        setValue(configuration.default_value);
        setStep(configuration.default_step);
        setTotalMinutes(configuration.default_total_minutes);
    }

    function setOnChangeEndValue() {
        setPriceCalculationError(null);
        if (estimatedPrice.type !== 'LOADING') {
            if (totalMinutes <= 0) {
                resetValues();
                getEstimatedPriceForParking(configuration.default_total_minutes);
            } else {
                getEstimatedPriceForParking(totalMinutes);
            }
        }
    }

    function getEstimatedPriceForParking(minutes: number) {
        setEstimatedPrice({type: 'LOADING'});
        getEstimatedPrice(operator, facilityCode, zone.zoneId, minutes).then((resp) => {
            if (resp.type === 'SUCCESS') {
                setEstimatedPrice({type: 'DATA', price: resp.data});
            } else if (resp.type === 'ERROR_MESSAGE') {
                setEstimatedPrice({type: 'ERROR', errorId: resp.errorId});
            } else {
                setEstimatedPrice({type: 'ERROR'});
            }
        });
    }

    const formatTime = (n: number) => n < 10 ? `0${n}` : n;

    const getDaysFromMinutes = () => formatTime(Math.floor(totalMinutes / 24 / 60));
    const getHoursFromMinutes = () => formatTime(Math.floor(totalMinutes / 60 % 24));
    const getRemainingMinutes = () => formatTime(totalMinutes % 60);

    const stepValue = (v: number) => Math.round(v * configuration.full_circle_steps) / configuration.full_circle_steps;

    function handleTimeChange(n: number) {
        const stepVal = stepValue(n);
        const prevStep = step;
        const currentStep = Math.ceil(configuration.full_circle_steps / 100 * (stepVal * 100));
        const currentCompletedCircles = completeCircles + getCompletedCircleModifier(prevStep, currentStep);
        const currentStepInMinutes = getStepInMinutesForCompletedCircles(currentCompletedCircles);
        const total = (currentStep * currentStepInMinutes) + getFullCirclesValue(currentCompletedCircles);

        if (!shouldUpdate(completeCircles, prevStep, currentStep, total)) {
            return;
        }

        setValue(stepVal);
        setStep(currentStep);
        setTotalMinutes(total);
        setParkUntilTime(moment().add(total, 'minutes'));
        setCompleteCircles(currentCompletedCircles);
    }

    function shouldUpdate(circles: number, prevStep: number, currentStep: number, total: number) {
        // Prevent scrolling back past 0
        if (circles === 0 && prevStep === 0 && !isInStartRange(currentStep)) {
            return false;
        }
        // Prevent scrolling past max
        if (total > configuration.maximum_total_minutes && currentStep > prevStep) {
            return false;
        }
        // Has change in value
        return !(prevStep === currentStep || Math.abs(prevStep - currentStep) === configuration.full_circle_steps);
    }

    function getCompletedCircleModifier(previousStep: number, currentStep: number) {
        if (isInEndRange(previousStep) && isInStartRange(currentStep)) {
            return 1;
        }
        if (isInStartRange(previousStep) && isInEndRange(currentStep)) {
            return -1;
        }
        return 0;
    }

    function getStepInMinutesForCompletedCircles(circles: number) {
        const threshold = Math.min(Math.floor(circles / 2), configuration.step_values_in_minutes.length - 1);
        return configuration.step_values_in_minutes[threshold];
    }

    function getFullCirclesValue(currentCompletedCircles: number) {
        if (currentCompletedCircles === 0) { return 0; }
        let sum = 0;
        for (let i = 0; i < currentCompletedCircles; i++) {
            sum += configuration.full_circle_steps * getStepInMinutesForCompletedCircles(i);
        }
        return sum;
    }

    function isInEndRange(currentStep: number) {
        const fullCircle = configuration.full_circle_steps;
        return [fullCircle - 4, fullCircle - 3, fullCircle - 2, fullCircle - 1, fullCircle].includes(currentStep);
    }

    function isInStartRange(currentStep: number) {
        return [0, 1, 2, 3, 4].includes(currentStep);
    }

    function renderEstimatedPrice() {
        switch (estimatedPrice.type) {
            case 'LOADING':
                return <Spinner size={'sm'} delay={'none'} />;
            case 'ERROR':
                setPriceCalculationError(estimatedPrice.errorId ? estimatedPrice.errorId : null);
                return <Spinner size={'sm'} delay={'none'} />;
            case 'DATA':
                setPriceCalculationError(null);
                return `${estimatedPrice.price.currency} ${estimatedPrice.price.price}`;
        }
    }

    return (
        <>
            <div className="ring-picker">
                <div className="ring-picker-info">
                    <div className="price-estimate">
                        <p>{Translation.messages().priceCalculator.estimated_price}</p>
                        <div className="ring-picker-info-value">
                            {renderEstimatedPrice()}
                        </div>
                    </div>
                    <div className="park-until">
                        <p>{Translation.messages().priceCalculator.park_until}</p>
                        <p className="ring-picker-info-value">{parkUntilTime.format('HH:mm')}</p>
                        <p className="ring-picker-info-value-alt">{parkUntilTime.format('ddd DD MMM')}</p>
                    </div>
                </div>
                <div className="ring-picker-circle">
                    <CircularInput
                        value={stepValue(value)}
                        onChange={handleTimeChange}
                        onChangeEnd={setOnChangeEndValue}
                    >
                        <CircularTrack strokeWidth={40} stroke={colorDefinitions.purple60primary} />
                        <CircularThumb r={25} stroke={colorDefinitions.primaryWhite} strokeWidth="3" fill={colorDefinitions.purple60primary} />
                        <text x={60} y={90} className="circular-date-output-number" textAnchor="middle" dy="0.3em">{getDaysFromMinutes()} :</text>
                        <text x={105} y={90} className="circular-date-output-number" textAnchor="middle" dy="0.3em">{getHoursFromMinutes()} :</text>
                        <text x={145} y={90} className="circular-date-output-number" textAnchor="middle" dy="0.3em">{getRemainingMinutes()}</text>
                        <text x={55} y={115} className="circular-date-output-text" textAnchor="middle" dy="0.3em">Day</text>
                        <text x={100} y={115} className="circular-date-output-text" textAnchor="middle" dy="0.3em">Hour</text>
                        <text x={145} y={115} className="circular-date-output-text" textAnchor="middle" dy="0.3em">Min</text>
                    </CircularInput>
                </div>
            </div>
        </>
    );
};
